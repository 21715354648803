import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContentPane from '../components/contentPane';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ContentPane title="Page Not Found" anchor="notfound">
      <h1>Invalid URL</h1>
    </ContentPane>
  </Layout>
);

export default NotFoundPage;
